<template>
  <div v-if="showAnalytic" class="container">
    <iframe v-if="base_role === 'administrator'" title="V3" width="100%" style="height: 100vh;" src="https://app.powerbi.com/reportEmbed?reportId=3900ecc1-ec87-4739-8a2d-ca9a26260d3b&autoAuth=true&ctid=e0b1e737-cfdc-47e7-87b2-2b52e41b2c44" frameborder="0" allowFullScreen="true"></iframe>
    <iframe v-else-if="ksk_info.bi_analytic_link" title="V3" width="100%" style="height: 100vh;" :src="ksk_info.bi_analytic_link" frameborder="0" allowFullScreen="true"></iframe>
    <v-alert v-else type="warning">Аналитика не найдена</v-alert>  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showAnalytic: false,
    }
  },
  computed: {
		...mapGetters({
      ksk_info: "ksk/GET_KSK_INFO",
			base_role: "auth/GET_USER_ROLE",
		}),
	},
  async created() {
    if(!this.ksk_info.hasOwnProperty('bi_analytic_link') && this.base_role !== 'administrator') {
      await this.$store.dispatch("ksk/load_ksk_info");
      this.showAnalytic = true
    } else {
      this.showAnalytic = true
    }
  },
}
</script>